import { Button } from '@nextui-org/button'
import Confetti from "react-confetti";
import getClientSupabase from '@/app/utils/getClientSupabase'
import { useEffect } from 'react'
import { useError } from '@/app/components/providers/ErrorProvider'

export function RegisterStep3() {
    function goToAdmin() {
        window.location.href = '/'  
    }

    const supabase = getClientSupabase()
    const { showError } = useError()

    useEffect(() => {
        supabase.auth.refreshSession().then((data)=> {
            if (data.error) showError( data.error.message, 'error')
        });
    }, [])
    


    return (
        <>
        <div className="my-5 gap-5">
            <div className='text-xl text-black font-bold'>Registration completed!</div>
            <div className='mt-3 text-black'>All is set now.</div>
            <div className='mt-10 text-sm'>We are here to help you, therefore if you have any question or comment, please reach out.</div>
        </div>
        <Button color='primary' onClick={()=>{ goToAdmin(); }}>Go to Admin Panel</Button>
        </>
    )
}
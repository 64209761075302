'use client'
import { Tables } from '@/app/types/supabase'
import OnboardingItem from './OnboardingItem'
import {
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  User,
} from '@nextui-org/react'
import getClientSupabase from '@/app/utils/getClientSupabase'
import React, { useState, useEffect } from 'react'
import { Database, Json } from '@/app/types/supabase'
import { useError } from '@/app/components/providers/ErrorProvider'
import { getMerchantFeatures, MerchantFeatures } from '@/app/components/merchantFeatures/getMerchantFeatures'
import { HeaderMerchantProfile } from '@/app/types/collection'
import CreateNewInterview from '@/app/(application)/interview_creator/components/CreateNewInterview';
import { BanknotesIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import NewSubscriptionButton from '@/app/components/registration/NewSubscriptionButton'

export default function OnboardingChecklist({
  merchant,
}: {
  merchant: HeaderMerchantProfile
}) {

  const supabase = getClientSupabase()
  const { showError } = useError()

  const [countInterviews, setCountInterviews] = React.useState<number|null>(null);
  const features = getMerchantFeatures(merchant.merchant?.merchant_features);

  function setLive () {
    supabase
    .from('merchant')
    .update({ status: 'active' })
    .eq('id', merchant.merchant.id)
    .then((data)=> {
      if (data.error) {
        showError(data.error.message, 'error');
      } else {
        showError('Your Portal is Live now.', 'success');
      }
    });
  }

  useEffect(() => {
      supabase
        .from('interview_def_set')
        .select('id', { count: 'exact', head: true })
        .eq('merchant_id', merchant.merchant.id)
        .eq('type', 'interview')
        .eq('status', 'active')
        .then((data)=> {
            setCountInterviews(data.count);
        });
  }, []);

  const defaultPageNames = ['JobMojito', 'JobMojito | Affiliate', 'JobMojito | Interview', 'JobMojito | Job Seeker', 'JobMojito | Coaching'];

  const stepLogo = (!((merchant.merchant.image_logo_url==='https://jobmojito.com/merchant-change-logo.png') || (merchant.merchant?.image_logo_url==='https://jobmojito.com/interview-mojito-logo.png')));
  const stepBusinesInfo = (merchant.merchant.billing_country!==undefined && merchant.merchant?.billing_country!== null && merchant.merchant?.billing_country!=='');
  const stepPublish = (merchant.merchant.status==='active');
  const stepPortalName = (merchant.merchant?.homepage_name!==undefined && merchant.merchant?.homepage_name!== null && merchant.merchant.homepage_name!=='' && !defaultPageNames.includes(merchant.merchant?.homepage_name));

  return (
    <div className='flex flex-col gap-1 mt-5'>
      {(merchant.merchant.status==='active') && 
      <OnboardingItem 
        isCompleted={true}
        title={'Your account is Active'}
        instructions={<></>}
      />
      }
      <OnboardingItem 
        isCompleted={stepPublish}
        title={'Publish the portal'}
        instructions={<>
          {(merchant.profile.name!==undefined && merchant.profile.name!=='') && stepLogo && stepBusinesInfo && stepPortalName?
            <div>When you are ready, click the button below to make your JobMojito portal publicly accessible.</div>
            :
            <div className='text-warning'>Complete all requirements first before the platform can be published.</div>
          }
            <div className='mt-2'>
              <Button color='primary' isDisabled={!((merchant.profile.name!==undefined && merchant.profile.name!=='') && stepLogo && stepBusinesInfo  && stepPortalName)} onClick={()=>{ setLive(); }} size='sm'>Make the portal public</Button>
            </div>
          </>}
      />
      {(merchant.profile.name===undefined || merchant.profile.name==='') &&
      <OnboardingItem 
        isCompleted={(merchant.profile.name!==undefined && merchant.profile.name!=='')}
        title={'Complete your personal profile'}
        instructions={<>
          <div>Fill-in your full name. <Link size='sm' href={'/profile'} className='text-secondary underline'>Edit Personal profile →</Link></div>
        </>}
      />
      }

      {!stepBusinesInfo &&
      <OnboardingItem 
        isCompleted={stepBusinesInfo}
        title={'Complete your business profile'}
        instructions={<>
          <div>Provide information about your Business and Main contact person. <Link size='sm' href={'/business_information'} className='text-secondary underline'>Edit Business information →</Link></div>
        </>}
      />}

      {(!stepPortalName && ((features.interview_portal===true) || (features.consumer_portal===true))) &&
      <OnboardingItem 
        isCompleted={stepPortalName}
        title={'Set your Portal name'}
        instructions={<>
          <div>Define your portal name. <Link size='sm' href={'/portal_customisation'} className='text-secondary underline'>Edit Portal configuration →</Link></div>
        </>}
      />}

      {(features.consumer_portal || features.interview_portal) && 
      <>
      <OnboardingItem 
        isCompleted={stepLogo}
        title={'Upload your logo'}
        instructions={<>
          <div>Upload your own logo's. <Link size='sm' href={'/portal_customisation'} className='text-secondary underline'>Upload your logo →</Link></div>
        </>}
      />
      </>
      }
      {((merchant.merchant.status === 'active') && (features.consumer_portal===true)) &&
      <div className='flex flex-row gap-3 mt-2 mb-2 text-black'>
        <QuestionMarkCircleIcon className='w-8 h-8 text-black' />
        <div className='flex flex-col gap-2'>
          <div className='font-bold text-lg'>Coaching portal checklist</div>
          <Link href={'https://intercom.help/jobmojito/en/articles/9652317-get-started-with-jobmojito-admin'} target='_blank' className='text-secondary underline'>Coaching portal: Follow our how-to onboarding article.</Link>
        </div>
      </div>
      }
      {((merchant.merchant.status === 'active') && (features.consumer_portal===true) && (features.consumer_sponsorship===false)) &&
      <div className='flex flex-row gap-3 mt-2 mb-2 text-black'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-success w-8 h-8">
            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
          </svg>
          <div className='flex flex-col gap-2'>
              <div className='font-bold text-lg'>Promote your new JobMojito platform</div>
              <div>Consider the JobMojito platform as your own product.</div>
              <div>
                <div className=''>We suggest:</div>
                <ul className='list-disc'>
                  <li className='text-sm ml-5'>Adding links to the footer of your web platform</li>
                  <li className='text-sm ml-5'>Including links in the footer of all your emails</li>
                  <li className='text-sm ml-5'>Sending targeted emails to job seekers; our support team will provide you with the email templates.</li>
                  <li className='text-sm ml-5'>Incorporating links to specific job listings (if applicable) within your coaching services at JobMojito</li>
                </ul>
              </div>
              <Link href={'/support'} className='text-secondary underline'>Contact our marketing expert; we succeed by working together →</Link>
          </div>
      </div>
      }
      {((merchant.merchant.status === 'active') && (features.interview_portal===true)) &&
      <div className='flex flex-row gap-3 mt-2 mb-2 text-black'>
        <QuestionMarkCircleIcon className='w-8 h-8 text-black' />
        <div className='flex flex-col gap-2'>
          <div className='font-bold text-lg'>Interview portal checklist</div>
          <Link href={'https://intercom.help/jobmojito/en/articles/9685579-get-started-with-interviewmojito-admin'} target='_blank' className='text-secondary underline'>Interview portal: Follow our how-to onboarding article.</Link>
        </div>
      </div>
      }
      {((merchant.merchant.status === 'active') && (features.interview_portal===true) && (countInterviews!==null) && (countInterviews>0)) &&
      <div className='flex flex-row gap-3 mt-2 mb-2 text-black'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-success w-8 h-8">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
          </svg>
          <div className='flex flex-col gap-2'>
              <div className='font-bold text-lg'>Interview Portal is Live</div>
              <div>Candidates can now apply to published Job Positions.</div>
              <div>Currently you have <b>{countInterviews}</b> active interviews. <Link href={'/interview_creator'} color='secondary'>Go to Interview list →</Link></div>
              <div>
                <div className=''>You can:</div>
                <ul className='list-disc'>
                  <li className='text-sm ml-5'>Publish direct interview link to any external Job Portals</li>
                  <li className='text-sm ml-5'>Candidates can arrive to your interview page and select job interview from there.</li>
                  <li className='text-sm ml-5'>You can invite candidates in the system or send them interview link directly.</li>
                </ul>
              </div>
              {merchant.merchant.interview_subscription_status==='trial' && 
              <div className='flex flex-row gap-5 mb-5 items-center'>
                <div>You don't have an active subscription.</div>
                <NewSubscriptionButton />
              </div>
              }
              <Link href={'/support'} color='secondary'>If you have questions, contact our support →</Link>
          </div>
        </div>
      }
      {((merchant.merchant.status === 'active') && (features.interview_portal===true) && (countInterviews!==null) && (countInterviews===0)) &&
      <div className='flex flex-row gap-3 mt-2 mb-2 text-black'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-success w-8 h-8">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
          </svg>
          <div className='flex flex-col gap-2'>
              <div className='font-bold text-lg'>Interview Portal is Live, without any active interviews</div>
              <div className='flex flex-row gap-5 mb-5 items-center'>
                <div>You don't have any active interview.</div>
                <CreateNewInterview xMojito={merchant} typeForm='interview' />
              </div>
              {merchant.merchant.interview_subscription_status==='trial' && 
              <div className='flex flex-row gap-5 mb-5 items-center'>
                <div>You don't have an active subscription.</div>
                <NewSubscriptionButton />
              </div>
              }
              <Link href={'/interview_creator'} color='secondary'>Go to Interview list →</Link>
              <Link href={'/support'} color='secondary'>If you you need help, contact us →</Link>
          </div>
        </div>
      }
      {(features.affiliate) && 
      <div className='flex flex-row gap-3 mt-5 mb-2 text-black'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-success w-8 h-8">
          <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
        </svg>
        <div className='flex flex-col gap-2'>
            <div className='font-bold text-lg'>Share your JobMojito Affiliate links</div>
            <div>
              <div className=''>With just one link you can:</div>
              <ul className='list-disc'>
                <li className='text-sm ml-5'>Gain combined revenue from all the platforms</li>
                <li className='text-sm ml-5'>As you are getting more refferals, more revenue share for you</li>
                <li className='text-sm ml-5'>Are you a large company and you want your own white label system, let us know!</li>
              </ul>
            </div>
            <Link href={'/affiliate_promote_pages'} color='secondary'>Get your Affiliate links →</Link>
        </div>
      </div>
      }
    </div>
  )
}

'use client'
import { Database } from '@/app/types/supabase'
import getClientSupabase from '@/app/utils/getClientSupabase'
import { useState } from 'react'
import LineChart from '../graphs/LineChart'
import { convertDataForLineChart } from '../graphs/utils'
import { Skeleton } from '@nextui-org/react'
import AnalyticsBlock from '@/app/(application)/analytics/components/AnalyticsBlock'

interface DataItem {
    day: string | null
    event_number: number | null
    event:
      | 'activation'
      | 'invitation'
      | 'resume_check'
      | 'cover_letter'
      | 'interview_coach'
      | 'interview'
      | 'interview_incomplete'
      | 'interview_reviewing'  
      | 'affiliate_page_visit'
      | 'affiliate_activation'
      | null
  }
  
export default function HomeGraph({merchant_id, isDemo = false} : {merchant_id:string, isDemo?: boolean}) {
  const supabase = getClientSupabase()

  const [analytics, setAnalytics] = useState<DataItem[]|undefined>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const activationAndInvitationColumns = [
    {
      key: 'date',
      label: 'Date',
    },
    {
      key: 'activation',
      label: 'Registration',
    },
    {
      key: 'invitation',
      label: 'Invitation',
    },
  ]

  if (!loaded) {
    if (merchant_id===undefined) {
        setLoaded(true);
    } else {
        let dateCalc = new Date();
        dateCalc.setDate(dateCalc.getDate() - 6);

        supabase
        .rpc('merchant_analytics_events_by_day', {
          in_merchant_id: merchant_id,
          in_date_from: dateCalc.toISOString().split('T')[0],
          in_date_to: new Date().toISOString().split('T')[0]  
        })
        .then((res2) => {
          if (res2.data!==null) {
            setAnalytics(res2.data);
            setLoaded(true);
          }
        });
    }
  }

  return (
    <Skeleton isLoaded={analytics!==undefined} className="rounded-lg">
      <AnalyticsBlock 
        columns={activationAndInvitationColumns}
        data={convertDataForLineChart(analytics || [], [
          'invitation',
          'activation',
          ], isDemo)}
      />
    </Skeleton>
  )
}

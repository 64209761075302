import { Button } from '@nextui-org/button'
import Confetti from "react-confetti";
import getClientSupabase from '@/app/utils/getClientSupabase'
import { useEffect } from 'react'
import { useError } from '@/app/components/providers/ErrorProvider'
import StripePricingTable from '@/app/components/stripe/StripePricingTable'

export function RegisterStepSubscribe() {
    return (
      <>
         <StripePricingTable />
      </>
    )
}
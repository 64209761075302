'use client'
import { useState } from 'react'
import { ReactNode } from 'react'

export default function OnboardingItem({isCompleted, title, instructions }: { isCompleted:boolean, title:string, instructions:ReactNode}) {

    const [loaded, setLoaded] = useState<boolean>(false);

    return (
        <>
                {(isCompleted?(
                    <>
                    <div className='flex flex-row gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-success w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        <div className='text-sm'>{title}</div>
                    </div>
                    </>
                ):(<>
                    <div className='flex flex-row gap-3 mt-2 mb-2 text-black'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-warning w-8 h-8">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                        </svg>
                        <div className='flex flex-col'>
                            <div className='font-bold text-lg'>{title}</div>
                            {instructions}
                        </div>
                    </div>
                </>))}
            
            
        </>
    )
}

'use client'
import React, { ChangeEvent, useState, Key, useEffect } from 'react'
import { CheckIcon } from '@heroicons/react/24/solid'
  
export function StepsComponent({ currentStep, changeStep, type }: { currentStep: number, changeStep: (step:number) => void, type: 'affiliate' | 'interview' | 'whitelabel' | 'coaching'}) {
    let steps = [
        { id: 1, name: (type==='affiliate'?'Affiliate program':(type==='interview'?'JobMojito | Interviews':(type==='coaching'?'JobMojito | Coaching':'JobMojito | Job seeker')))},
        { id: 2, name: 'Your registration'},
      ]

      if (type==='interview') {
        steps=[
            ... steps,
         //   { id: 3, name: 'Subscribe'},
            { id: 3, name: 'Finish'},
        ]
      } else {
        steps=[
            ... steps,
            { id: 3, name: 'Finish'},
        ]
      }
  
      return (
        <nav aria-label="Progress">
        <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
            {steps.map((step, stepIdx) => (
            
            <li key={step.name} className="relative md:flex md:flex-1">
                {step.id<currentStep ? (
                <>
                    <button onClick={()=>{ if(currentStep!==3) changeStep(step.id); }} onKeyDown={()=>{ if(currentStep!==3) changeStep(step.id); }}>
                        <span className={"flex items-center px-6 py-4 text-sm font-medium cursor-pointer "+ (currentStep!==3 && 'hover:bg-default-100')}>
                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-blue-600 group-hover:bg-blue-800">
                            <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                        <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                        </span>
                    </button>
                </>
                ) : step.id===currentStep ? (
                <>
                    <span className="flex items-center px-6 py-4 text-sm font-medium">
                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-blue-600">
                        <span className="text-blue-600">{step.id}</span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-blue-600">{step.name}</span>
                    </span>
                </>
                ) : (
                <>
                    <span className="flex items-center px-6 py-4 text-sm font-medium">
                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                        <span className="text-gray-500 group-hover:text-gray-900">{step.id}</span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-500">{step.name}</span>
                    </span>
                </>
                )}

                {stepIdx !== steps.length - 1 ? (
                <>
                    {/* Arrow separator for lg screens and up */}
                    <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                    <svg
                        className="h-full w-full text-gray-300"
                        viewBox="0 0 22 80"
                        fill="none"
                        preserveAspectRatio="none"
                    >
                        <path
                        d="M0 -2L20 40L0 82"
                        vectorEffect="non-scaling-stroke"
                        stroke="currentcolor"
                        strokeLinejoin="round"
                        />
                    </svg>
                    </div>
                </>
                ) : null}
            </li>
            ))}
        </ol>
        </nav>
    )
}  
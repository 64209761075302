'use client'
import getClientSupabase from '@/app/utils/getClientSupabase'
import { useState } from 'react'
import { convertDataForLineChart } from '../../../components/graphs/utilsFin'
import { Skeleton } from '@nextui-org/react'
import clientGetCookies from '@/app/components/core/clientCookies'
import { Database, Tables, Enums } from "@/app/types/supabase";
import AnalyticsBlock from '@/app/(application)/analytics/components/AnalyticsBlock'

interface DataItem {
    day: string | null
    fin: number | null
  }
  
export default function PaymentGraph({payment_type, processing_status, isDemo=false, colorIndex=undefined}:{payment_type?:Enums<'type_payment_type'>|undefined, processing_status: 'new'|'completed', isDemo?:boolean, colorIndex?:number}) {
  const supabase = getClientSupabase()

  const [analytics, setAnalytics] = useState<DataItem[]|undefined>();
  const [loaded, setLoaded] = useState<boolean>(false);

  if (!loaded) {
    supabase.auth.getSession().then((res)=> {
        const merchant_id = res.data.session?.user?.app_metadata?.merchant_id;
        const merchantIdLocal = clientGetCookies('set_merchant_id') || merchant_id;

        if (merchantIdLocal===undefined) {
            setLoaded(true);
        } else if (payment_type!==undefined) {
              supabase
              .rpc('merchant_analytics_commissions', {
                in_merchant_id: merchantIdLocal,
                in_payment_type: payment_type,
                in_processing_status: processing_status
              })
              .then((res2) => {
                if (res2.data!==null) {
                  setAnalytics(res2.data);
                  setLoaded(true);
                }
              });
            } else {
              supabase
                .rpc('merchant_analytics_commissions', {
                  in_merchant_id: merchantIdLocal,
                  in_processing_status: processing_status
                })
                .then((res2) => {
                  if (res2.data!==null) {
                    setAnalytics(res2.data);
                    setLoaded(true);
                  }
                });
            }
      });
  }

  const commissionColumns = [
    {
      key: 'date',
      label: 'Date',
    },
    {
      key: 'commission',
      label: 'Commissions',
    },
  ]

  return (
    <Skeleton isLoaded={analytics!==undefined} className="rounded-lg">
      <AnalyticsBlock 
        type='bar'
        colorIndex={colorIndex}
        columns={commissionColumns}
        data={convertDataForLineChart(analytics || [], [
          'commission',
          ], isDemo)}
      />
    </Skeleton>
  )
}

interface DataItem {
  day: string | null
  event_number: number | null
  event:
    | 'activation'
    | 'invitation'
    | 'resume_check'
    | 'cover_letter'
    | 'interview_coach'
    | 'interview'
    | 'interview_incomplete'
    | 'interview_reviewing'
    | 'affiliate_page_visit'
    | 'affiliate_activation'
    | null
}

export interface ResultObject {
  isDemo: boolean
  isEmpty: boolean
  rows: ResultItem[]
}

export interface ResultItem {
  date: string
  [key: string]: number | string
}

// This function used for converting Supabase view output to format, accepted by the Line Chart
function convertDataForLineChart(
  data: DataItem[],
  neededEvents: string[],
  isDemo: boolean
): ResultObject {
  let isOnlyZero=true;
  let seedrandom = require('seedrandom');

  const result: Record<string, ResultItem> = {}
  data.forEach(({ day, event_number, event }) => {
    if (!day || !event) return

    // If the current event is not in the array of needed events, skip this iteration
    if (!neededEvents.includes(event)) return

    const date = day.split('-').reverse().join('/').slice(0, 10)

    if (!result[date]) {
      result[date] = { date, [event as string]: event_number || 0 }
    } else {
      result[date][event] = event_number || 0
    }
    if (result[date][event]!==0) isOnlyZero=false;
  })

  // generate random data for the demo account
  if (isDemo && isOnlyZero) {
    let myrng = new seedrandom(data[0]?.day+neededEvents.toString());

    data.forEach(({ day, event_number, event }) => {
      if (!day || !event) return
  
      // If the current event is not in the array of needed events, skip this iteration
      if (!neededEvents.includes(event)) return
  
      const date = day.split('-').reverse().join('/').slice(0, 10)
      result[date][event] = Math.round(myrng()*100)
    })  
  }

  return { 
    isDemo: ((isDemo && isOnlyZero)),
    isEmpty: (!isDemo && isOnlyZero),
    rows: Object.values(result) 
  }
}

export { convertDataForLineChart }

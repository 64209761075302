import { Button } from '@nextui-org/button'
import { Divider } from '@nextui-org/react';

export function RegisterStep1({ completedStep, subMerchant }: { completedStep: () => void, subMerchant:boolean}) {
    return (
        <>
        <div className="mt-2 mb-5 gap-5">
            <div className='text-xl text-black font-bold'>Create AI Interview platform</div>
            <div className='mt-3 text-black'>In just a few minutes, you can enable your candidates to apply to your vacant positions.</div>

            <ul className='text-sm text-black list-disc ml-5 mt-2'>
                <li>Utilize our pre-defined job position catalog, leverage AI to create new listings, or craft custom interviews.</li>
                <li>Create an impressive public Career page.</li>
                <li>Efficiently manage submitted interviews, candidates, and the selection process for the next round.</li>
                <li>Save up to 90% of your effort during screening interviews!</li>
                <li>Customize branding for avatars, portals, and user experience</li>
            </ul>
            <div className='mt-5'>Please follow the onboarding checklist, and should you have any queries, don't hesitate to contact us via the <a href="https://jobmojito.com/support" target="_blank" className='underline text-primary'>support page</a>.</div>
            {!subMerchant && <>
                <Divider className='mt-5' />
                <div className='mt-5 text-xs'>Note: If you received an invitation to this platform and ended up here, it indicates you are not using the email address to which the invitation was sent. Should you have any questions, please contact our support. <a href="https://jobmojito.com/support" target="_blank" className='underline text-primary'>support page</a></div>
            </>}
        </div>
        <Button color='primary' onClick={()=>{ completedStep(); }}>Next</Button>
        </>
    )
}
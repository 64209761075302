import BlockCard from '@/app/components/BlockCard'
import BlockTitle from '@/app/components/core/BlockTitle'
import BarChart from '@/app/components/graphs/BarChart'
import LineChart from '@/app/components/graphs/LineChart'
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  getKeyValue,
} from '@nextui-org/react'
import { ResultObject } from '@/app/components/graphs/utils'

export type AnalyticsTableItem = {
  key: string
  label: string
}

export default function AnalyticsBlock({
  data,
  columns = undefined,
  type = 'line',
  colorIndex = undefined
}: {
  data: ResultObject,
  columns?: AnalyticsTableItem[] | undefined
  type?: 'line'|'bar'
  colorIndex?: number
}) {
  return (
      <div className="flex">
        {type==='line'?
        <LineChart data={data.rows} className="w-full" columns={columns} isDemo={data.isDemo} isEmpty={data.isEmpty} colorIndex={colorIndex} />
        :
        <BarChart data={data.rows} className="w-full" columns={columns} isDemo={data.isDemo} isEmpty={data.isEmpty} colorIndex={colorIndex} />
        }
      </div>
  )
}

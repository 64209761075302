'use client'
import {Button} from "@nextui-org/button";
import {  Modal,   ModalContent,   ModalHeader,   ModalBody,   ModalFooter} from "@nextui-org/modal";
import Confetti from "react-confetti";

export default function WelcomeModal({
  isDefaultOpen,
}: {
  isDefaultOpen: boolean
}) {
  return (
    <Modal defaultOpen={isDefaultOpen} size='lg' scrollBehavior='inside'>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalBody>
              <Confetti width={500} height={500} recycle={true} tweenDuration={15000} numberOfPieces={50} />

              <div className="my-5 gap-5">
                <div className='text-xl text-black font-bold'>Registration completed! 🎉</div>
                <div className='mt-3 text-black'><b>Congratulations on becoming a JobMojito Merchant!</b> In just a few minutes, you can have your own monetized Job Seeker coaching platform with a 25%+ commission.</div>
                <div className='mt-3'>Follow the onboarding checklist, and if you have any questions, please get in touch using the support page.</div>
                <div className='mt-3'>Together, we will achieve GREAT things!</div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button aria-label='Start use our service' color="primary" onPress={onClose}>
                Start Now!
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

interface DataItem {
  day: string | null
  fin: number | null
}

export interface ResultObject {
  isDemo: boolean
  isEmpty: boolean
  rows: ResultItem[]
}

interface ResultItem {
  date: string
  [key: string]: number | string
}

// This function used for converting Supabase view output to format, accepted by the Line Chart
function convertDataForLineChart(
  data: DataItem[],
  neededEvents: string[],
  isDemo: boolean
): ResultObject {
  let seedrandom = require('seedrandom');

  let isOnlyZero=true;

  let result: Record<string, ResultItem> = {}
  data.forEach(({ day, fin}) => {
    if ((!day || !'commission') && (!isDemo)) return

    // If the current event is not in the array of needed events, skip this iteration
    if ((!neededEvents.includes('commission')) && (!isDemo)) return
    if (day) {
    const date = day.split('-').reverse().join('/').slice(0, 10)
      if (!result[date]) {
        result[date] = { date, ['commission']: fin || 0 }
      } else {
        result[date].commission = fin || 0
      }
      if (result[date].commission!==0) isOnlyZero=false;
    }
  })

  // generate random data for the demo account
  if (isDemo && isOnlyZero) {
    if (data.length===0) {
      let dateCalcStart = new Date();
      dateCalcStart.setDate(dateCalcStart.getDate() - 30);
      let dateCalcStartText = dateCalcStart.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' })

      let myrng = new seedrandom(dateCalcStartText+neededEvents.toString());
      for(let i=30; i>=0; i--) {
        let dateCalc = new Date();
        dateCalc.setDate(dateCalc.getDate() - i);
        let dateCalcText = dateCalc.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' })

        const date = dateCalcText.split('-').reverse().join('/').slice(0, 10)
        result[date] = { date, ['commission']: Math.round(myrng()*100) }
      }
    } else {
      let myrng = new seedrandom(data[0].day+neededEvents.toString());
      data.forEach(({ day}) => {
        if (day) {
          const date = day.split('-').reverse().join('/').slice(0, 10)
          result[date].commission = Math.round(myrng()*100)
        }
      })  
    }
  }
  
  return { 
    isDemo: ((isDemo && isOnlyZero)),
    isEmpty: ((!isDemo && isOnlyZero)),
    rows: Object.values(result) 
  } 
}

export { convertDataForLineChart }

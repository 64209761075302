'use client'
import { Database } from '@/app/types/supabase'
import getClientSupabase from '@/app/utils/getClientSupabase'
import { useState, useEffect } from 'react'
import KpiCard from '@/app/components/home/kpiCard'
import clientGetCookies from '@/app/components/core/clientCookies'

export default function HomeKpiMerchant({category}: {category:'merchant_commissions'|'merchant_credits'}) {

    const [loaded, setLoaded] = useState<boolean>(false);
    const [kpiComissionEur, setKpiComissionEur] = useState<string|undefined>()
    const [kpiComissionUsd, setKpiComissionUsd] = useState<string|undefined>()

    const [kpiSponsorshipLight, setKpiSponsorshipLight] = useState<string|undefined>()
    const [kpiSponsorshipManager, setKpiSponsorshipManager] = useState<string|undefined>()

    useEffect(() => {
        if (!loaded) {
            const supabase = getClientSupabase()

            supabase.auth.getSession().then((result) => {
            const sessionUser = result.data.session?.user.app_metadata;
            const merchantIdLocal = clientGetCookies('set_merchant_id') || sessionUser?.merchant_id;
        
            if (category==='merchant_commissions') {
                supabase.rpc('merchant_commissions', { in_merchant_id: merchantIdLocal }).then((res) => {
                    if (res.data !== null) {
                        const result = res.data as { eur: number, usd: number }
                        setKpiComissionEur(result.eur.toFixed(2));
                        setKpiComissionUsd(result.usd.toFixed(2));
                    }
                    setLoaded(true);
                });
            } else {
                supabase.rpc('merchant_credits', { in_merchant_id: merchantIdLocal }).then((res) => {
                    if (res.data !== null) {
                        const result = res.data as { sponsorship_light: number, sponsorship_manager: number }
                        if (result.sponsorship_light!==undefined && result.sponsorship_light!==null && result.sponsorship_light!==0) setKpiSponsorshipLight(result.sponsorship_light.toString())
                        setKpiSponsorshipManager(result.sponsorship_manager.toString())
                        //setKpi(res.data.toFixed(2));
                    }
                    setLoaded(true);
                });
            }
        });
        }
    }, [])

    return (
    <>
        {(category==='merchant_commissions') &&
        <>
            <KpiCard title={'Outstanding Commission'} value={(kpiComissionEur?kpiComissionEur:'')} type={'currency'} loaded={kpiComissionEur!==undefined} />
            <KpiCard title={'Outstanding Commission'} value={(kpiComissionUsd?kpiComissionUsd:'')} type={'currencyUsd'} loaded={kpiComissionUsd!==undefined} />
        </>
        }
        {(category==='merchant_credits') &&
        <>
            <KpiCard title={'Sponsorships'} value={(kpiSponsorshipManager?kpiSponsorshipManager:'')} type={'string'} loaded={kpiSponsorshipManager!==undefined} />
            {(kpiSponsorshipLight!==undefined && kpiSponsorshipLight!=='') && <KpiCard title={'Sponsorships Light'} value={(kpiSponsorshipLight?kpiSponsorshipLight:'')} type={'string'} loaded={kpiSponsorshipLight!==undefined} /> }
        </>
        }
    </>
    )
}

'use client'
import {
  Bar,
  CartesianGrid,
  BarChart as ReBarChart,
  ResponsiveContainer,
  Tooltip,
  Label,
  XAxis,
  Legend,
  YAxis,
} from 'recharts'

type DataItem = {
  date: string
  [key: string]: number | string
}

export type AnalyticsTableItem = {
  key: string
  label: string
}

const colorsMap = [
  'rgb(236 72 153)',
  'rgb(6 182 212)',
  'rgb(249 115 22)',
  'rgb(139 92 246)',
  'rgb(20 184 166)',
  'rgb(239 68 68)',
  'rgb(59 130 246)',
  'rgb(168 85 247)',
]

export default function BarChart({
  data,
  className = '',
  isDemo = false,
  isEmpty = false,
  columns = undefined,
  colorIndex = undefined
}: {
  data: DataItem[]
  className?: string
  isDemo?: boolean
  isEmpty?: boolean
  columns?: AnalyticsTableItem[] | undefined
  colorIndex?: number
}) {
  const keys = data.reduce((acc: string[], item) => {
    Object.keys(item).forEach((key) => {
      if (!acc.includes(key) && key !== 'date') {
        acc.push(key)
      }
    })
    return acc
  }, [])

  return (
    <div className={'h-[320px] mt-2 '+ className}>
      <ResponsiveContainer width="100%" height="100%">
      {((data.length>0) && (isDemo || (!isDemo && !isEmpty))) ?
        <ReBarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" className='text-xs'>
            {isDemo && <Label value="Demo data" offset={-100} position="insideTop" className='fill-black font-bold text-4xl' /> }
          </XAxis>
          <YAxis className='text-xs' />
          <Tooltip />

          {/*<Legend />*/}
          {keys.map((key, index) => (
              <Bar
              key={index}
              type="monotone"
              name={(columns!==undefined?columns.find((el)=> { return el.key===key })?.label:key)}
              dataKey={key}
              fill={(colorIndex!==undefined?colorsMap[colorIndex]:colorsMap[index % 10])}
              strokeWidth={1}
              className='opacity-90'
              stackId="a"
            />
            ))}
            <Legend />

        </ReBarChart>
        :
        <div className='w-full h-full flex flex-col items-center content-middle justify-center'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z" />
          </svg>
          <div>no data</div>
        </div>
      }
    
      </ResponsiveContainer>
    </div>
  )
}
